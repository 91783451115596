<template>
  <div>
    <el-page-header @back="dynamic" content="目录管理" style="margin-bottom: 15px;">
    </el-page-header>
    <el-button type="text">
      <el-button size="mini" type="primary" @click="dialog = true">新增目录</el-button>
    </el-button>
    <el-drawer
        title="新增目录"
        :visible.sync="dialog"
        direction="rtl"
        custom-class="demo-drawer"
        ref="drawer"
        size="40%"
    >
      <div class="demo-drawer__content" style="padding: 50px">
        <el-form :model="form">
          <el-form-item label="目录名称" :label-width="formLabelWidth">
            <el-input v-model="form.menuName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="目录类型" :label-width="formLabelWidth">
            <el-select v-model="form.isMain" placeholder="请选择目录类型">
              <el-option label="主目录" :value="false"></el-option>
              <el-option label="子目录" :value="true"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if=form.isMain label="所属主目录" :label-width="formLabelWidth">
            <el-select v-model="form.mainMenuId" placeholder="请选择主目录">
              <el-option
                  v-for="item in mainMenuData"
                  :key="item.menuId"
                  :label="item.menuName"
                  :value="item.menuId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if=form.isMain label="目录链接" :label-width="formLabelWidth">
            <el-input v-model="form.subMenuUri" autocomplete="off"></el-input>
          </el-form-item>
          <div class="demo-drawer__footer">
            <el-button @click="cancelForm">取 消</el-button>
            <el-button type="primary" @click="addMenu(form)">确定</el-button>
          </div>
        </el-form>
      </div>
    </el-drawer>
    <el-row>
      <el-col :span="6">
        <span>主菜单</span>
        <el-table
          :data="mainMenuData"
          highlight-current-row
          @current-change="handleCurrentChange"
          style="width: 100%; margin: auto"
          :cell-style="{padding:0}"
          height="510">
        <el-table-column
            fixed
            prop="id"
            label="序号"
            width="50">
          <template slot-scope="scope">
            {{ scope.$index + 1}}
          </template>
        </el-table-column>
        <el-table-column
            prop="menuName"
            label="目录名称">
        </el-table-column>
      </el-table>
      </el-col>
      <el-col :span="1"></el-col>
      <el-col :span="17">
        <span>子菜单</span>
        <el-table
            :data="subMenuData"
            style="width: 100%; margin: auto"
            :cell-style="{padding:0}"
            height="510">
          <el-table-column
              fixed
              prop="id"
              label="序号"
              width="50">
            <template slot-scope="scope">
              {{ scope.$index + 1}}
            </template>
          </el-table-column>
          <el-table-column
              prop="menuName"
              label="目录名称"
              width="200">
          </el-table-column>
          <el-table-column
              prop="menuChildrenUri"
              label="目录链接">
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "permission",
  data() {
    return {
      mainMenuData: [],
      subMenuData: [],
      dialog: false,
      isMain: false,
      formLabelWidth: '100px',
      form: {
        menuName: '',
        isMain: false,
        mainMenuId: '',
        subMenuUri: ''
      },
    }
  },
  mounted() {
    this.getMainMenuData()
    this.getSubMenuData()
  },
  methods: {
    dynamic() {
      this.$router.push("dynamic")
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    getMainMenuData() {
      this.$http({
        method: 'get',
        url: '/api/menu/getMainMenuList'
      }).then((res) =>{
        this.mainMenuData = res.data.data
      })
    },
    getSubMenuData() {
      this.$http({
        method: 'get',
        url: '/api/menu/getSubMenuList'
      }).then((res) =>{
        this.subMenuData = res.data.data
      })
    },
    cancelForm(){
      this.form.mainMenuId = ''
      this.form.menuName = ''
      this.form.subMenuUri = ''
      this.form.isMain = false
      this.dialog = false
    },
    addMenu(form){
      this.$confirm('是否提交' , '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        if (form.menuName === '' || form.menuName === null) {
          this.$message({
            type: 'error',
            message: '目录名称不能为空'
          })
          return
        }
        if (this.form.isMain) {
          if (this.form.mainMenuId === '' || form.mainMenuId === null) {
            this.$message({
              type: 'error',
              message: '请选择所属主目录'
            })
            return
          }
          if (this.form.subMenuUri === '' || form.subMenuUri === null) {
            this.$message({
              type: 'error',
              message: '链接不能为空'
            })
            return
          }
        }
        this.$http.post( '/api/menu/addMenu', form).then((res) =>{
          if (res.data.status) {
            this.$message({
              type: 'success',
              message: res.data.msg
            });
            this.getMainMenuData()
            this.getSubMenuData()
            this.cancelForm()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
      }).catch(()=>{
        this.$message({
          type: 'error',
          message: "取消提交"
        })
      })
    }
  }
}
</script>

<style scoped>
.w-50 {
  width: 50%;
  margin-left: 18%;
}
</style>